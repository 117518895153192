<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2"
      primary-title
    >
      Policies and Agreement
    </v-card-title>

    <v-card-text>
      <div class="row q-space">
        <div class="col-sm-12">
          <h5>REGISTRATION PROCESS</h5>
        </div>
        <div class="col-sm-12">
          <ol>
            <li>
              The student completes the application form and sends it to the school by
              fax, mail or email at least 2 weeks before the program starting date.
            </li>
            <li>
              The school then sends pre-admission letter and an invoice to the student if 
              the application is approved.
            </li>
            <li>
              The student pays tuition fees and other fees identified in the pre-admission
              letter. (Wire Transfer Information is on the next page)
            </li>
            <li>
              Once the school has received payment, the school will issue a Letter of
              Acceptance to the student.
            </li>
            <li>
              The student then brings the Letter of Acceptance and tuition receipt to the
              Canadian Embassy in their home country in order to obtain visa (if necessary).
            </li>
            <li>
              Once the visa has been obtained, the student may book the trip to Canada.
            </li>
            <li>
              Student pays Medical Insurance fee, Custodian Declaration and Guardian
              Fee, Text Book Deposit, Student Activity Fee, and Student Residence or
              Home stay fee(optional).
            </li>
            <li>
              Airport pick up service must be requested and booked two weeks in advance.
            </li>
          </ol>
        </div>
      </div>
      <div class="row q-space">
        <div class="col-sm-12">
          <h5>FEE SCHEDULE</h5>
        </div>
        <div class="col-sm-12">
          <div class="col-sm-12 fee-item">
            <label>Application Fee (non-refundable)</label>
            <div class="right">
              $450
            </div>
          </div>
          <div class="col-sm-12 fee-item">
            <label>Tuition Fee</label>
            <ul>
              <li>
                Top University Green Path Program
                <div class="right">
                  $32,500/Year
                </div>
              </li>
              <li>
                Top University Green Path Plus Program
                <div class="right">
                  $42,000/Year
                </div>
              </li>
              <li>
                Regular Program
                <div class="right">
                  $22,500/Year
                </div>
              </li>
              <li>
                Semester Exchange Student Program
                <div class="right">
                  $5,000/term
                </div>
              </li>
            </ul>
          </div>
          <div class="col-sm-12 fee-item">
            <label>Medical Insurance</label>
            <div class="right">
              $600/year
            </div>
          </div>
          <div class="col-sm-12 fee-item">
            <label>Material Fee</label>
            <div class="right">
              $260/year
            </div>
          </div>
          <div class="col-sm-12 fee-item">
            <label>Activity Fee</label>
            <div class="right">
              $600/year
            </div>
          </div>
          <div class="col-sm-12 fee-item">
            <label>Friday Fest (meal fee)</label>
            <div class="right">
              $600(10 month)
            </div>
          </div>
          <div class="col-sm-12 fee-item">
            <label>Locker Fee (include deposit)</label>
            <div class="right">
              $200
            </div>
          </div>
          <div class="col-sm-12 fee-item">
            <label>Custodian Fee (if under 18 years old)</label>
            <div class="right">
              $1,200/year
            </div>
          </div>
          <div class="col-sm-12 fee-item">
            <label>Airport Pick Up or Return</label>
            <div class="right">
              $180/time
            </div>
          </div>
          <div class="col-sm-12 fee-item">
            <label>Bank Charge</label>
            <div class="right">
              $600/year
            </div>
          </div>
          <div class="col-sm-12 fee-item">
            <label>School Uniform</label>
            <div class="right">
              $450
            </div>
          </div>
          <div class="col-sm-12 fee-item">
            <label>Book Deposit</label>
            <div class="right">
              $1000/year
            </div>
          </div>
          <div class="col-sm-12 fee-item">
            <label>Student Residence Fee</label>
            <ul>
              <li>
                On-campus House (including meals, rental 10 + 1 months)
                <div class="right">
                  $2360 - $2700/month
                </div>
              </li>
              <li>
                Student Condo (including meals, rental 10 + 1 months)
                <div class="right">
                  $2100/month
                </div>
              </li>
              <li>
                Key deposit
                <div class="right">
                  $200
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row q-space">
        <div class="col-sm-12">
          <h5>CLASS AND HOLIDAY SCHEDULE</h5>
        </div>
        <div class="col-sm-12">
          <table
            class="table table-bordered"
            style="font-size: 12"
          >
            <tr>
              <td style="width: 50%">
                <label>Fall Term</label>
                <p>Term 1 September & October</p>
                <p>Term 2 November & December</p>
              </td>
              <td style="width: 50%">
                <label>Summer School</label>
                <p>Term 1 July</p>
                <p>Term 2 August</p>
              </td>
            </tr>
            <tr>
              <td>
                <label>Winter & Spring Term</label>
                <p>Term 1 January & February</p>
                <p>Term 2 March & April</p>
                <p>Term 3 May & June</p>
              </td>
              <td>
                <label>Christmas Holiday</label> Approximately 2 weeks
                <br> <label>March Break</label> The second or third week
                of March
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row q-space">
        <div class="col-sm-12">
          <h5>REFUND POLICES</h5>
        </div>
        <div class="col-sm-12">
          <ol>
            <li>
              A binding contract is created between the applicant and the school when the
              applicant or a designated representative of the applicant accepts a place for 
              the applicant in a course or program offered by the school and pays the 
              required tuition.
            </li>
            <li>
              Application fees and other administrative charges are non-refundable and are not 
              included in the tuition fee.
            </li>
            <li>
              Tuition fees are <b>NOT</b> refundable when a student withdraws from a course or a 
              program.
            </li>
            <li>
              A student who is expelled by the school is not entitled to a refund of any fees listed 
              in this contract.
            </li>
            <li>
              Fees are non-refundable unless the student’s study permit is denied by 
              Citizenship and Immigration Canada.
            </li>
            <li>
              If an international applicant’s application for a study permit or temporary 
              resident visa is refused, the following documents are required for processing a 
              tuition refund:
              <ol type="a">
                <li>
                  The original copy of the student’s written request and the completed application 
                  form for tuition refund;
                </li>
                <li>
                  The original copy of the decline letter from Citizenship and Immigration Canada;
                </li>
                <li>
                  The original copy of the official acceptance letter issued by the School;
                </li>
                <li>
                  The original copy of the tuition fee receipt issued by the School;
                </li>
              </ol>
              When all of the above documents are received and verified, the school shall
              refund all the tuition fees to the applicant. The refund shall be processed no later
              than two months after the school has received all required documentation 
              listed in this section as items a), b), c) and d).
            </li>
            <li>
              Notification of withdrawal/cancellation from a course(s)
              or program(s), or deferral from a course(s) or program(s) of
              study must be made in writing to the School.
            </li>
          </ol>
        </div>
      </div>
      <div class="row q-space">
        <div class="col-sm-12">
          <h5>AGREEMENT</h5>
        </div>
        <div class="col-sm-12">
          <ul>
            <li>
              Smoking or Vaping is not permitted on school grounds, in
              homestay or in dormitory. I declare that I am not a smoker.
            </li>
            <li>
              Fees are non-refundable unless student’s study permit
              is denied by Citizenship and Immigration Canada.
            </li>
            <li>
              I have read and acknowledged the Refund Policy and
              Regulations in this application package.
            </li>
            <li>
              If I am accepted as a student at TCA, I hereby agree to
              abide by all the rules and regulations of the school which are
              published on the school website (<a
                href="https://www.tacademy.ca"
                target="_blank"
              >www.tacademy.ca</a>)
            </li>
            <li>
              I hereby declare that the information given in this
              application form is to the best of my knowledge complete and
              correct.
            </li>
          </ul>
        </div>
      </div>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        flat
        @click="accept"
      >
        I accept
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'AgreementDialog',
  props: [
    'dialog',
  ],
  methods: {
    accept() {
      this.$emit('accept')
    },
  },
}
</script>

<style>
li {
  padding: 3px
}
.q-space {
  padding: 10px
}
</style>
