<template>
  <div>
    <v-container grid-list-md>
      <v-layout
        v-if="application"
        row
        wrap
      >
        <v-flex xs12>
          <v-layout
            v-if="application"
            row
            wrap
          >
            <v-flex xs12>
              <v-layout
                row
                wrap
              >
                <v-flex xs9>
                  <h2>{{ `Admission Application` }}</h2>
                  <span class="subheading">
                    {{ `Application ID: ${application.applicationId}` }}
                  </span>
                </v-flex>
              </v-layout>
              <v-divider class="my-3" />
            </v-flex>
            <v-flex xs12>
              <v-expansion-panel
                v-model="panel"
                popout
                expand
              >
                <v-expansion-panel-content>
                  <template v-slot:header>
                    <div>PERSONAL INFORMATION</div>
                  </template>
                  <v-form
                    ref="formBasicInfo"
                    v-model="forms.formBasicInfo"
                    lazy-validation
                  >
                    <v-card>
                      <v-card-text>
                        <v-layout
                          row
                          wrap
                        >
                          <v-flex xs12>
                            <h4>Basic Information:</h4>
                          </v-flex>
                          <v-flex xs3>
                            <v-text-field
                              v-model="application.lastName"
                              class="required"
                              label="Family Name"
                              :rules="[
                                rules.required,
                                rules.length(0, 20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs3>
                            <v-text-field
                              v-model="application.middleName"
                              label="Middle Name"
                              :rules="[
                                rules.length(0, 20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs3>
                            <v-text-field
                              v-model="application.firstName"
                              class="required"
                              label="Given Name"
                              :rules="[
                                rules.required,
                                rules.length(0, 20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs3>
                            <v-text-field
                              v-model="application.preferName"
                              label="Preferred Name"
                              :rules="[
                                rules.length(0, 20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-menu
                              ref="dobMenu"
                              v-model="dobMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="application.dateOfBirth"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              min-width="290px"
                            >
                              <v-text-field
                                slot="activator"
                                v-model="application.dateOfBirth"
                                class="required"
                                label="Date of Birth"
                                placeholder="yyyy-mm-dd"
                                prepend-icon="event"
                                :rules="[
                                  rules.required
                                ]"
                              />
                              <v-date-picker
                                v-model="application.dateOfBirth"
                                @input="$refs.dobMenu.save(application.dateOfBirth)"
                              />
                            </v-menu>
                          </v-flex>
                          <v-flex xs4>
                            <v-autocomplete
                              v-model="application.birthCountry"
                              class="required"
                              :items="countryOptions"
                              item-text="english_desc"
                              item-value="id"
                              label="Country of Birth"
                              :rules="[
                                rules.required
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-select
                              v-model="application.gender"
                              class="required"
                              :items="genderOptions"
                              item-text="text"
                              item-value="value"
                              label="Gender"
                              :rules="[
                                rules.required,
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-select
                              v-model="application.residentStatus"
                              class="required"
                              :items="residentStatusOptions"
                              item-text="text"
                              item-value="value"
                              label="Status in Canada"
                              :rules="[
                                rules.required,
                              ]"
                            />
                          </v-flex>
                          <v-flex xs12>
                            <h4>Address:</h4>
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field
                              v-model="application.address"
                              class="required"
                              label="Current Address"
                              :rules="[
                                rules.required,
                                rules.length(0, 200)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-text-field
                              v-model="application.city"
                              class="required"
                              label="City"
                              :rules="[
                                rules.required,
                                rules.length(0, 20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-text-field
                              v-model="application.province"
                              class="required"
                              label="State/Province"
                              :rules="[
                                rules.required,
                                rules.length(0, 20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-text-field
                              v-model="application.postalCode"
                              class="required"
                              label="Postal/Zip Code"
                              :rules="[
                                rules.required,
                                rules.length(0,20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs12>
                            <h4>Contact Information:</h4>
                          </v-flex>
                          <v-flex xs6>
                            <v-text-field
                              v-model="application.homephone"
                              class="required"
                              label="Home Phone"
                              :rules="[
                                rules.required,
                              ]"
                            />
                          </v-flex>
                          <v-flex xs6>
                            <v-text-field
                              v-model="application.cellphone"
                              class="required"
                              label="Mobile"
                              :rules="[
                                rules.required,
                              ]"
                            />
                          </v-flex>
                          <v-flex xs7>
                            <v-text-field
                              v-model="application.email"
                              class="required"
                              label="Email"
                              :rules="[
                                rules.required,
                                rules.email
                              ]"
                            />
                          </v-flex>
                          <v-flex xs3>
                            <v-text-field
                              v-model="application.wechatID"
                              label="WeChat ID"
                              :rules="[
                                rules.length(0,50)
                              ]"
                            />
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                      <v-card-actions text-right>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          @click="save('formBasicInfo')"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-expansion-panel-content>

                <v-expansion-panel-content>
                  <template v-slot:header>
                    <div>ACADEMIC PROGRAM APPLY FOR</div>
                  </template>
                  <v-form
                    ref="formApply"
                    v-model="forms.formApply"
                    lazy-validation
                  >
                    <v-card>
                      <v-card-text>
                        <v-layout
                          row
                          wrap
                        >
                          <v-flex xs5>
                            <v-select
                              v-model="application.program"
                              class="required"
                              :items="programOptions"
                              label="Program"
                              :rules="[
                                rules.required
                              ]"
                            />
                          </v-flex>
                          <v-flex xs3>
                            <v-select
                              v-model="application.grade"
                              class="required"
                              :items="['9','10','11','12']"
                              item-text="text"
                              item-value="value"
                              label="Grade"
                              :rules="[
                                rules.required
                              ]"
                            />
                          </v-flex>
                          <v-flex xs12>
                            <h4>Start Term:</h4>
                          </v-flex>
                          <v-flex xs3>
                            <v-select
                              v-model="application.applyYear"
                              class="required"
                              :items="yearOptions"
                              item-text="text"
                              item-value="value"
                              label="Year"
                              :rules="[
                                rules.required
                              ]"
                            />
                          </v-flex>
                          <v-flex xs2>
                            <v-select
                              v-model="application.applyMonth"
                              class="required"
                              :items="monthOptions"
                              item-text="text"
                              item-value="value"
                              label="Month"
                              :rules="[
                                rules.required
                              ]"
                            />
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                      <v-card-actions text-right>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          @click="save('formApply')"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-expansion-panel-content>

                <v-expansion-panel-content>
                  <template v-slot:header>
                    <div>EDUCATION BACKGROUND</div>
                  </template>
                  <v-form
                    ref="formSchInfo"
                    v-model="forms.formSchInfo"
                    lazy-validation
                  >
                    <v-card>
                      <v-card-text>
                        <v-layout
                          row
                          wrap
                        >
                          <v-flex xs12>
                            <h4>Most Recent School Information:</h4>
                          </v-flex>
                          <v-flex xs7>
                            <v-text-field
                              v-model="application.firstSchoolName"
                              class="required"
                              label="School Name"
                              :rules="[
                                rules.required,
                                rules.length(0, 100)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field
                              v-model="application.firstSchoolAddress"
                              class="required"
                              label="Address"
                              :rules="[
                                rules.required,
                                rules.length(0, 200)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-menu
                              ref="firstSchoolStartMenu"
                              v-model="firstSchoolStartMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="application.firstSchoolStart"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              min-width="290px"
                            >
                              <v-text-field
                                slot="activator"
                                v-model="application.firstSchoolStart"
                                class="required"
                                label="From"
                                placeholder="yyyy-mm-dd"
                                prepend-icon="event"
                                :rules="[
                                  rules.required,
                                  rules.length(0, 20)
                                ]"
                              />
                              <v-date-picker
                                v-model="application.firstSchoolStart"
                                @input="$refs.firstSchoolStartMenu.save(application.firstSchoolStart)"
                              />
                            </v-menu>
                          </v-flex>
                          <v-flex xs4>
                            <v-menu
                              ref="firstSchoolEndMenu"
                              v-model="firstSchoolEndMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="application.firstSchoolEnd"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              min-width="290px"
                            >
                              <v-text-field
                                slot="activator"
                                v-model="application.firstSchoolEnd"
                                class="required"
                                label="To"
                                placeholder="yyyy-mm-dd"
                                prepend-icon="event"
                                :rules="[
                                  rules.required,
                                  rules.length(0, 20)
                                ]"
                              />
                              <v-date-picker
                                v-model="application.firstSchoolEnd"
                                @input="$refs.firstSchoolEndMenu.save(application.firstSchoolEnd)"
                              />
                            </v-menu>
                          </v-flex>
                          <v-flex xs8>
                            <v-text-field
                              v-model="application.firstSchoolAward"
                              label="Certification or Diploma awarded"
                              :rules="[
                                rules.length(0, 20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs12>
                            <h4>2nd Previous School Information:</h4>
                          </v-flex>
                          <v-flex xs7>
                            <v-text-field
                              v-model="application.secondSchoolName"
                              label="School Name"
                              :rules="[
                                rules.length(0, 100)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs12>
                            <v-text-field
                              v-model="application.secondSchoolAddress"
                              label="Address"
                              :rules="[
                                rules.length(0, 200)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-menu
                              ref="secondSchoolStartMenu"
                              v-model="secondSchoolStartMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="application.secondSchoolStart"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              min-width="290px"
                            >
                              <v-text-field
                                slot="activator"
                                v-model="application.secondSchoolStart"
                                label="From"
                                placeholder="yyyy-mm-dd"
                                prepend-icon="event"
                                :rules="[
                                  rules.length(0, 20)
                                ]"
                              />
                              <v-date-picker
                                v-model="application.secondSchoolStart"
                                @input="$refs.secondSchoolStartMenu.save(application.secondSchoolStart)"
                              />
                            </v-menu>
                          </v-flex>
                          <v-flex xs4>
                            <v-menu
                              ref="secondSchoolEndMenu"
                              v-model="secondSchoolEndMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="application.secondSchoolEnd"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              min-width="290px"
                            >
                              <v-text-field
                                slot="activator"
                                v-model="application.secondSchoolEnd"
                                label="To"
                                placeholder="yyyy-mm-dd"
                                prepend-icon="event"
                                :rules="[
                                  rules.length(0, 20)
                                ]"
                              />
                              <v-date-picker
                                v-model="application.secondSchoolEnd"
                                @input="$refs.secondSchoolEndMenu.save(application.secondSchoolEnd)"
                              />
                            </v-menu>
                          </v-flex>
                          <v-flex xs8>
                            <v-text-field
                              v-model="application.secondSchoolAward"
                              label="Certification or Diploma awarded"
                              :rules="[
                                rules.length(0, 20)
                              ]"
                            />
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          @click="save('formSchInfo')"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-expansion-panel-content>

                <v-expansion-panel-content>
                  <template v-slot:header>
                    <div>PARENT/GUARDIAN CONTACT</div>
                  </template>
                  <v-form
                    ref="formParent"
                    v-model="forms.formParent"
                    lazy-validation
                  >
                    <v-card>
                      <v-card-text>
                        <v-layout
                          row
                          wrap
                        >
                          <v-flex xs12>
                            <h4>1st Parent Information:</h4>
                          </v-flex>
                          <v-flex xs4>
                            <v-text-field
                              v-model="application.firstParentName"
                              class="required"
                              label="Name"
                              :rules="[
                                rules.required,
                                rules.length(0, 50)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-text-field
                              v-model="application.firstParentRelation"
                              class="required"
                              label="Relationship"
                              :rules="[
                                rules.required,
                                rules.length(0, 20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-text-field
                              v-model="application.firstParentPhone"
                              class="required"
                              label="Phone"
                              :rules="[
                                rules.required,
                                rules.digits,
                                rules.length(10, 20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs5>
                            <v-text-field
                              v-model="application.firstParentEmail"
                              class="required"
                              label="Email"
                              :rules="[
                                rules.required,
                                rules.email
                              ]"
                            />
                          </v-flex>
                          <v-flex xs5>
                            <v-text-field
                              v-model="application.firstParentContactMethod"
                              label="Other Contact Info"
                              :rules="[
                                rules.length(0, 20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs12>
                            <h4>2nd Parent Information:</h4>
                          </v-flex>
                          <v-flex xs4>
                            <v-text-field
                              v-model="application.secondParentName"
                              label="Name"
                              :rules="[
                                rules.length(0, 50)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-text-field
                              v-model="application.secondParentRelation"
                              label="Relationship"
                              :rules="[
                                rules.length(0, 20)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs4>
                            <v-text-field
                              v-model="application.secondParentPhone"
                              label="Phone"
                            />
                          </v-flex>
                          <v-flex xs5>
                            <v-text-field
                              v-model="application.secondParentEmail"
                              label="Email"
                              :rules="[
                                rules.email
                              ]"
                            />
                          </v-flex>
                          <v-flex xs5>
                            <v-text-field
                              v-model="application.secondParentContactMethod"
                              label="Other Contact Info"
                              :rules="[
                                rules.length(0, 100)
                              ]"
                            />
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          @click="save('formParent')"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-expansion-panel-content>

                <v-expansion-panel-content>
                  <template v-slot:header>
                    <div>OTHER INFORMATION</div>
                  </template>
                  <v-form
                    ref="formOthers"
                    v-model="forms.formOthers"
                    lazy-validation
                  >
                    <v-card>
                      <v-card-text>
                        <v-layout
                          row
                          wrap
                        >
                          <v-flex xs3>
                            <v-menu
                              ref="insExpMenu"
                              v-model="insExpMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="application.healthInsuranceExpire"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              min-width="290px"
                            >
                              <v-text-field
                                slot="activator"
                                v-model="application.healthInsuranceExpire"
                                label="Health Insurance Expire Date"
                                placeholder="yyyy-mm-dd"
                                prepend-icon="event"
                              />
                              <v-date-picker
                                v-model="application.healthInsuranceExpire"
                                @input="$refs.insExpMenu.save(application.healthInsuranceExpire)"
                              />
                            </v-menu>
                          </v-flex>
                          <v-flex xs3>
                            <v-text-field
                              v-model="application.schoolContact"
                              class="required"
                              label="Name of Contact Person of School"
                              :rules="[
                                rules.required,
                                rules.length(0, 50)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs3>
                            <v-text-field
                              v-model="application.source"
                              label="Source"
                              :rules="[
                                rules.length(0, 50)
                              ]"
                            />
                          </v-flex>
                          <v-flex
                            v-if="user.privilege === 'applicant'"
                            xs4
                          >
                            <v-text-field
                              v-model="application.referralCode"
                              label="Referral Code"
                              :rules="[
                                rules.length(0, 13)
                              ]"
                            />
                          </v-flex>
                          <v-flex xs6>
                            <v-radio-group
                              v-model="application.accommodationServ"
                              mandatory
                              row
                            >
                              <template v-slot:label>
                                <div><strong>Require Accommodation Service?</strong></div>
                              </template>
                              <v-radio
                                label="Yes"
                                value="y"
                                required
                              />
                              <v-radio
                                label="No"
                                value="n"
                                required
                              />
                            </v-radio-group>
                          </v-flex>
                          <v-flex xs6>
                            <v-radio-group
                              v-model="application.custodianServ"
                              mandatory
                              row
                            >
                              <template v-slot:label>
                                <div><strong>Require Custodianship Service?</strong></div>
                              </template>
                              <v-radio
                                label="Yes"
                                value="y"
                                required
                              />
                              <v-radio
                                label="No"
                                value="n"
                                required
                              />
                            </v-radio-group>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          @click="save('formOthers')"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-expansion-panel-content>

                <v-expansion-panel-content>
                  <template v-slot:header>
                    <div>SUPPORTING DOCUMENT</div>
                  </template>
                  <v-form
                    ref="formDocs"
                    v-model="forms.formDocs"
                    lazy-validation
                  >
                    <v-card>
                      <v-card-text>
                        <v-layout
                          row
                          wrap
                        >
                          <v-flex xs12>
                            <span>Note: Only PDF files are accepted, however, you can convert your images to a pdf using <image-to-pdf-convertor /></span>
                          </v-flex>
                          <v-flex xs12>
                            <v-data-table
                              :headers="headers"
                              :items="docs"
                              class="elevation-1"
                              hide-actions
                            >
                              <template v-slot:items="props">
                                <td>
                                  <span
                                    v-if="props.item.required"
                                    style="color:red"
                                  >*</span>
                                  {{ props.item.displayName }}
                                  <span
                                    v-if="props.item.required"
                                    style="color:red"
                                  >(required)</span>
                                </td>
                                <td class="text-xs-right">
                                  {{ props.item.fileName ? 'Provided' : 'Not Provided' }}
                                </td>
                                <td class="text-xs-right">
                                  <v-btn
                                    v-if="props.item.fileName"
                                    flat
                                    @click="viewFile(props.item.docType)"
                                  >
                                    {{ props.item.fileName }}
                                  </v-btn>
                                  <v-btn
                                    v-else
                                    flat
                                    disabled
                                  >
                                    Not Avaliable
                                  </v-btn>
                                </td>
                                <!-- <td class="text-xs-right">
                                  <v-btn>Help</v-btn>
                                </td> -->
                                <td class="text-xs-right">
                                  <v-btn @click="selectedUpload=props.item, uploadPopup = true">
                                    Upload
                                  </v-btn>
                                </td>
                              </template>
                            </v-data-table>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-flex>
            <v-flex xs12>
              <v-layout
                align-center
                justify-center
                fill-height
                wrap
              >
                <v-btn
                  v-if="user.privilege === 'rep' && !onReview"
                  large
                  color="primary"
                  @click="doReview()"
                >
                  Review
                </v-btn>
                <v-btn
                  v-if="user.privilege === 'rep' && onReview"
                  large
                  color="primary"
                  @click="agentSubmit"
                >
                  Submit
                </v-btn>
                <v-btn
                  v-if="user.privilege === 'applicant' || user.privilege === 'application' && !onPreview"
                  large
                  color="primary"
                  @click="expandAll(), onPreview = true"
                >
                  Preview
                </v-btn>
                <v-btn
                  v-if="user.privilege === 'applicant' || user.privilege === 'application' && onPreview"
                  large
                  color="primary"
                  @click="next"
                >
                  Continue
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-dialog
          v-model="policy"
          scrollable
          max-width="800px"
        >
          <agreement-dialog @accept="accept" />
        </v-dialog>
        <v-dialog
          v-model="uploadPopup"
          width="400px"
        >
          <fileupload-dialog
            :app-id="application.id"
            :selected-upload="selectedUpload"
            @newFileUploaded="addFile"
          />
        </v-dialog>
        <!-- <v-dialog
          v-model="signRequestPopup"
          width="400px"
          persistent
        >
          <v-card>
            <v-card-title
              class="headline grey lighten-2"
              primary-title
            >
              Signature Request
            </v-card-title>
            <v-card-text>
              Do you want to send signature request email to the applicant now?
            </v-card-text>
            <v-card-actions>
              <v-btn
                flat
                color="primary"
                @click="signRequest()"
              >
                Yes
              </v-btn>
              <v-btn
                flat
                color="primary"
                @click="$router.push(`/reps/${user.userId}/applications`)"
              >
                No
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
        <v-dialog
          v-model="signRequestPopup"
          width="400px"
          persistent
        >
          <v-card>
            <v-card-title
              class="headline grey lighten-2"
              primary-title
            >
              Signature Request
            </v-card-title>
            <v-card-text>
              An email will be sent to applicant for request to sign the application.
            </v-card-text>
            <v-card-actions>
              <v-btn
                flat
                color="primary"
                @click="proceedSubmit()"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="signPopup"
          width="400px"
          persistent
        >
          <v-card>
            <v-card-title
              class="headline grey lighten-2"
              primary-title
            >
              Applicant Signature
            </v-card-title>
            <v-card-text>
              <v-layout
                row
                wrap
              >
                <v-flex xs12>
                  <VueSignaturePad
                    ref="signPopup"
                    width="350px"
                    height="250px"
                    :options="{onBegin: () => { $refs.signPopup.resizeCanvas() }}"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn
                flat
                color="primary"
                @click="signClear('signPopup')"
              >
                Clear
              </v-btn>
              <v-spacer />
              <v-btn
                flat
                color="primary"
                @click="cancel"
              >
                Cancel
              </v-btn>
              <v-btn
                flat
                color="primary"
                @click="signSave"
              >
                {{ age >= 18 ? 'Submit' : 'Next' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="parSignPopup"
          width="450px"
          persistent
        >
          <v-card>
            <v-card-title
              class="headline grey lighten-2"
              primary-title
            >
              Parent/Gardian Signature(Optional)
            </v-card-title>
            <v-card-text>
              <v-layout
                row
                wrap
              >
                <v-flex xs12>
                  <VueSignaturePad
                    ref="parSignPopup"
                    width="350px"
                    height="250px"
                    :options="{onBegin: () => { $refs.parSignPopup.resizeCanvas() }}"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn
                flat
                color="primary"
                @click="signClear('parSignPopup')"
              >
                Clear
              </v-btn>
              <v-spacer />
              <v-btn
                flat
                color="primary"
                @click="cancel"
              >
                Cancel
              </v-btn>
              <v-btn
                flat
                color="primary"
                @click="parSignSave"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import validate from '@/custom/validate'
import AgreementDialog from '@/components/application/form/AgreementDialog.vue'
import FileUploadDialog from '@/components/application/form/FileUploadDialog.vue'
import ImageToPDFConvertor from '@/components/application/form/ImageToPDFConvertor.vue'

export default {
  components: {
    'agreement-dialog': AgreementDialog,
    'fileupload-dialog': FileUploadDialog,
    'image-to-pdf-convertor': ImageToPDFConvertor,
  },
  data() {
    return {
      panel: 0, // [true, false, false, false, false, false, false],
      application: '',
      rules: {},
      admYearMonth: null,
      termMonths: ['January', 'March', 'May', 'July', 'August', 'September', 'November', 'December'],
      genderOptions: [
        { text: 'Male', value: 'Male' },
        { text: 'Female', value: 'Female' },
      ],
      residentStatusOptions: [
        { text: 'Canadian Citizen', value: '1' },
        { text: 'Permanent Resident', value: '3' },
        { text: 'Visa Student', value: '4' },
        { text: 'Refugee Status', value: '7' },
        { text: 'Other Visa', value: '5' },
      ],
      programOptions: [],
      countryOptions: [],
      dobMenu: false,
      insExpMenu: false,
      firstSchoolStartMenu: false,
      firstSchoolEndMenu: false,
      secondSchoolStartMenu: false,
      secondSchoolEndMenu: false,
      forms: {
        formBasicInfo: false,
        formApply: false,
        formSchInfo: false,
        formParent: false,
        formOthers: false,
        formDocs: false,
      },
      headers: [
        {
          text: 'Document',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Status',
          sortable: false,
        },
        {
          text: 'Uploaded File',
          sortable: false,
        },
        // {
        //   text: 'Help',
        //   sortable: false,
        // },
        {
          text: 'Upload',
          sortable: false,
        },
      ],
      files: {
        passport: {},
        transcript: {},
        creditSummary: {},
      },
      selectedUpload: {},
      uploadPopup: false,
      policy: false,
      signPopup: false,
      parSignPopup: false,
      onReview: false,
      onPreview: false,
      currentYear: null,
      currentMonth: null,
      signRequestPopup: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters.user || {}
    },
    appId() {
      return this.$route.params.id
    },
    yearOptions() {
      return this.admYearMonth ? Object.keys(this.admYearMonth) : []
    },
    monthOptions() {
      return this.admYearMonth && this.applyYear ? this.admYearMonth[this.applyYear] : []
    },
    applyYear() {
      return this.application.applyYear
    },
    age() {
      if (!this.application) { return 0 }
      return this.calculate_age(new Date(this.application.dateOfBirth)) || 0
    },
    docs() {
      const docs = []
      docs.push({
        displayName: 'Passport',
        docType: 'passport',
        fileName: this.application.passportFileName,
        dataUrl: this.application.passport,
        required: true,
      })
      docs.push({
        displayName: 'Transcript',
        docType: 'transcript',
        fileName: this.application.transcriptFileName,
        dataUrl: this.application.transcript,
        required: false,
      })
      docs.push({
        displayName: 'Credit Summary',
        docType: 'creditSummary',
        fileName: this.application.creditSummaryFileName,
        dataUrl: this.application.creditSummary,
        required: false,
      })
      return docs
    },
  },
  watch: {
    applyYear() {
      if (this.admYearMonth && !this.monthOptions.includes(this.application.applyMonth)) {
        this.application.applyMonth = null
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const { token } = this.$route.query
      this.currentYear = new Date(this.$store.getters.serverTime).getFullYear() || new Date().getFullYear()
      this.currentMonth = new Date(this.$store.getters.serverTime).toLocaleString('default', { month: 'long' }) || new Date().toLocaleString('default', { month: 'long' })
      
      if (token) {
        this.$store.dispatch('consumeToken', token)
      }
      this.rules = validate.rules
      this.$store.dispatch('applications/one', this.appId)
        .then((data) => {
          this.application = data
        })
      this.$store.dispatch('regions/allCountries')
        .then((data) => { this.countryOptions = data })
      
      this.$store.dispatch('app/getAdmissionYearMonth')
        .then((data) => { this.admYearMonth = data })

      this.$store.dispatch('app/getSchoolPrograms')
        .then((data) => { this.programOptions = data })
    },
    save(form) {
      if (!this.$refs[form].validate()) {
        return
      }
      this.$store.dispatch('applications/update', this.application)
        .then((data) => {
          this.application = data
          this.$store.dispatch('showMessage', { state: 'success', message: 'Application Saved' })
        })
    },
    completeValidate() {
      this.panel = []
      const hasInvalid = Object.keys(this.forms).some((form) => {
        let isInvalid = !this.$refs[form].validate()
        if (form === 'formDocs' && !this.application.passportFileName) {
          isInvalid = true
          this.$store.dispatch('showMessage', { state: 'error', message: 'Please upload your passport..' })
        }
        this.panel.push(isInvalid)
        return isInvalid
      })
      return !hasInvalid
    },
    doReview() {
      if(!this.completeValidate()) {
        return
      }
      this.expandAll()
      this.onReview = true
    },
    agentSubmit() {
      if (!this.completeValidate()) return
      this.signRequestPopup = true // need promise here ******
    },
    proceedSubmit() {
      this.submit()
        .then(() => {
          this.$store.dispatch('showMessage', { state: 'success', message: 'Application submitted..' })
          this.signRequest()
            .finally(()=>this.$router.push(`/reps/${this.user.userId}/applications`))
        })
    },
    async signRequest() {
      return await this.$store.dispatch('applications/signRequest', this.application.id)
        .then(() => {
          this.$store.dispatch('showMessage', { state: 'success', message: 'request has been sent to applicant\'s email' })
          this.$router.push(`/reps/${this.user.userId}/applications`)
        })
        .catch(() => this.$store.dispatch('showMessage', { state: 'error', message: 'unable to send email to the applicant' }))
    },
    next() {
      if (this.completeValidate()) {
        this.policy = true
      }
    },
    accept() {
      this.policy = false
      this.signPopup = true
    },
    signClear(pad) {
      this.$refs[pad].clearSignature()
    },
    signSave() {
      const { isEmpty, data } = this.$refs.signPopup.saveSignature()
      if (isEmpty) {
        return
      }

      [, this.application.signature] = data.split(',')

      if (this.age < 18) {
        this.signPopup = false
        this.parSignPopup = true
        return
      }
      this.application.status = 'signed'
      this.sign()
        .then(() => {
          if (this.user.privilege === 'application') {
            this.$store.dispatch('logout')
            this.$router.push('/')
            this.$store.dispatch('showMessage', { state: 'success', message: 'Application sent successfully...' })
            return
          }
          this.$router.push(`/users/${this.user.userId}/applications`)
        })
    },
    parSignSave() {
      const { isEmpty, data } = this.$refs.parSignPopup.saveSignature()
      // if (isEmpty) {
      //   return
      // }

      // desctruct and assign second value to parent signature
      if (!isEmpty) {
        [, this.application.parentSignature] = data.split(',')
      }
      this.application.status = 'signed'
      this.sign()
        .then(() => {
          if (this.user.privilege === 'application') {
            this.$store.dispatch('logout')
            this.$router.push('/')
            this.$store.dispatch('showMessage', { state: 'success', message: 'Application sent successfully...' })
            return
          }
          this.$router.push(`/users/${this.user.userId}/applications`)
        })
    },
    cancel() {
      this.signClear('signPopup')
      this.signClear('parSignPopup')
      this.signPopup = false
      this.parSignPopup = false
    },
    calculate_age(dob) {
      const diffMS = Date.now() - dob.getTime()
      const ageDT = new Date(diffMS)
      return Math.abs(ageDT.getUTCFullYear() - 1970)
    },
    addFile({ docType, fileName }) {
      this.application[docType+'FileName'] = fileName
      this.uploadPopup = false
    },
    viewFile(docType) {
      const actionString = `get${docType.charAt(0).toUpperCase() + docType.slice(1)}`
      this.$store.dispatch(`applications/${actionString}`, this.application.id)
        .then((data) => {
          const file = new Blob([data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
        })
    },
    expandAll() {
      this.panel = [true, true, true, true, true, true]
      // Object.entries(this.forms).forEach(([key]) => {
      //   console.log(this.forms[key])
      //   this.forms[key] = true
      // })
    },
    async submit() {
      await this.$store.dispatch('applications/submit', this.application)
        .then((data) => {
          this.application = data
        })
    },
    async sign() {
      await this.$store.dispatch('applications/sign', this.application)
        .then((data) => {
          this.application = data
        })
    }
  },
}
</script>

<style>
</style>
