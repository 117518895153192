<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="purple lighten-2"
        dark
        v-on="on"
      >
        Image to PDF
      </v-btn>
    </template>
    <v-card>
      <v-card-title
        class="headline grey lighten-2"
        primary-title
      >
        Select files to convert
      </v-card-title>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <v-flex xs12>
            <v-text-field
              :value="combinedFileName"
              label="Select File"
              prepend-icon="attach_file"
              @click="selectFile('input')"
            />
            <input
              ref="input"
              type="file"
              style="display: none"
              accept="image/*"
              multiple="multiple"
              required
              @change="onFileSelect"
            >
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue-grey"
          class="white--text"
          @click="convert()"
        >
          Convert
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import jsPDF from 'jspdf'

export default {
  data() {
    return {
      dialog: false,
      files: {},
      sizeAllow: 1024 * 1024 * 1, //1MB
      fileData: [],
      fileNames: [],
    }
  },
  computed: {
    combinedFileName() {
      console.log('trigger combinedFileName')
      return Array.from(this.files).reduce((accum, { name }) => `${accum}, ${name}`, '')
    },
    combinedFileSize() {
      console.log('trigger combinedFileSize')
      return Array.from(this.files).reduce((accum, { size }) => accum + size, 0)
    }
  },
  watch: {
    selectedUpload() {
      this.files = {}
    },
  },
  methods: {
    selectFile(v) {
      this.$refs[v].click()
    },
    reset() {
      this.files = {}
    },
    onFileSelect(e) {
      const { files } = e.target
      this.files = files
    },
    async convert() {
      if(this.combinedFileSize > this.sizeAllow) {
        this.$store.dispatch('showMessage', { state: 'error', message: 'combined file size limited to 1MB or less' })
        return 
      }
      this.$store.dispatch('setLoading')

      let dataURLs = await Promise.all(
        Array.from(this.files)
          .map(f => this.readAsDataURL(f))
      )

      const doc = new jsPDF()
      dataURLs.forEach((dataURL, i) => {
        doc.addImage(dataURL, 'JPEG', 15, 40, 180, 0)
        if (i !== dataURLs.length - 1) {
          doc.addPage()
        }
      })

      doc.save('combined')
      this.reset()
      this.dialog = false
      this.$store.dispatch('unsetLoading')
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject)=>{
        let reader = new FileReader()
        reader.onload = function(){
          return resolve(reader.result)
        }
        reader.readAsDataURL(file)
      })
    },
  },
}
</script>

<style>

</style>
