<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2"
      primary-title
    >
      Upload {{ selectedUpload.displayName }}
    </v-card-title>
    <v-card-text>
      <v-layout
        row
        wrap
      >
        <v-flex xs12>
          <v-text-field
            :value="fileName"
            label="Select File"
            prepend-icon="attach_file"
            @click="selectFile(selectedUpload.docType)"
          />
          <input
            :ref="selectedUpload.docType"
            type="file"
            style="display: none"
            accept="application/pdf"
            required
            @change="onFileSelect"
          >
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue-grey"
        class="white--text"
        @click="submitFile()"
      >
        Upload
        <v-icon
          right
          dark
        >
          cloud_upload
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: [
    'appId',
    'selectedUpload',
  ],
  data() {
    return {
      fileData: null,
      fileName: null,
    }
  },
  computed: {
    appFile() {
      let appFile = {}
      appFile.id = this.appId
      appFile.docType = this.selectedUpload.docType
      appFile.fileName = this.fileName
      appFile.data = this.fileData
      return appFile
    },
  },
  watch: {
    selectedUpload() {
      this.fileData = null
      this.fileName = null
    },
  },
  methods: {
    selectFile(v) {
      this.$refs[v].click()
    },
    onFileSelect(e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      console.log(file.size)
      if(file.size > 1024 * 1024 * 1) {
        this.$store.dispatch('showMessage', { state: 'error', message: 'file size limited to 1MB or less' })
        return 
      }

      reader.onload = (event) => {
        [, this.fileData] = event.target.result.split(',')
      }
      reader.readAsDataURL(file)
      this.fileName = file.name
    },
    submitFile() {
      if (!this.fileData) {
        alert('Please select a file')
        return
      }
      const actionString = `update${this.selectedUpload.displayName.replace(/\s/g, '')}`
      
      this.$store.dispatch(`applications/${actionString}`, this.appFile)
        .then(() => {
          this.$emit('newFileUploaded', this.appFile)
          this.fileData = null
          this.fileName = null
        })
    },
  },
}
</script>

<style>

</style>
